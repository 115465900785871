<template>
	<div>
		<!-- If modal have a "pui-select" or "pui-date-field", set property :overflow="false" -->
		<!-- If fixed width is needed, set property :widthDialog="XXX" -->
		<pui-modal-dialog-form
			:titleText="$t('modal.edimensaje.reprocesar.title')"
			:modelName="modelName"
			:dialogName="edimensajeReprocesarModal"
			:componentHeaderName="'edimensaje-form-header'"
			:onOk="onOkReprocesarModal"
			:onShow="onShowReprocesarModal"
		>
			<template slot="message">
					<v-row dense>
						<v-col cols="12">
							<label style="font-weight: bold;color:#166bf2">{{$t('modal.edimensaje.reprocesar.confirm')}}</label>
						</v-col>
					</v-row>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('modal.edimensaje.reenviar.title')"
			:modelName="modelName"
			:dialogName="edimensajeReenviarModal"
			:componentHeaderName="'edimensaje-form-header'"
			:onOk="onOkReenviarModal"
			:onShow="onShowReenviarModal"
		>
			<template slot="message">
					<v-row dense>
						<v-col cols="12">
							<label style="font-weight: bold;color:#166bf2">{{$t('modal.edimensaje.reenviar.confirm')}}</label>
						</v-col>
					</v-row>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('modal.edimensaje.verMensaje.title')"
			:modelName="modelName"
			:dialogName="edimensajeVerMensajeModal"
			:componentHeaderName="'edimensaje-form-header'"
			disable-cancel
			widthDialog="100vw"
			:onShow="onShowVerMensajeModal"
		>
			<template slot="message" slot-scope="data">
				<v-layout wrap>
					<v-flex xs12>
						<pui-text-area :id="`mensajeV${idSufix}`" :key="'mensajeV_modal_'+keymess" v-model="data.modalData.mensaje" toplabel outlined solo disabled></pui-text-area>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('modal.edimensaje.updMensaje.title')"
			:modelName="modelName"
			:dialogName="edimensajeUpdMensajeModal"
			:componentHeaderName="'edimensaje-form-header'"
			widthDialog="100vw"
			:onShow="onShowUpdMensajeModal"
			:onOk="onOkUpdMensajeModal"
		>
			<template slot="message" slot-scope="data">
				<v-layout wrap>
					<v-flex xs12>
						<pui-text-area2
							:id="`mensajeU${idSufix}`"
							:key="'mensajeU_modal_'+keymess"
							v-model="data.modalData.mensaje"
							toplabel
							outlined
							solo
							:sanitize="false"
							>
						</pui-text-area2>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>

export default {
	name: 'edimensaje-modals',
	data() {
		return {
			edimensajeReprocesarModal: 'edimensajeReprocesarAction',
			edimensajeReenviarModal: 'edimensajeReenviarAction',
			edimensajeVerMensajeModal: 'edimensajeVerMensajeAction',
			edimensajeUpdMensajeModal: 'edimensajeUpdMensajeAction',
			idSufix: `-${this.modelName}-modal`,
			keymess:0
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	methods: {
		onOkReprocesarModal(modalData) {
			const body = {
				emPksMulti: modalData.emPksMulti
			};

			const url = this.$store.getters.getModelByName(this.modelName).url.reprocesarMensaje;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('modal.edimensaje.reprocesar.title');
			return new Promise((resolve) => {
				this.$puiRequests.patchRequest(
					url,
					body,
					(response) => {
						// Valid response, do code and close modal
						console.log(response);
						resolve(true);
						//this.$puiNotify.success(this.$puiI18n.t('puiaction.notifySuccess'), title);
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					},
					(e) => {
						// Error response, do code and not close modal
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title);
						resolve(false);
					}
				);
			});
		},
		onShowReprocesarModal(modalData) {
			// Put here code to execute before open modal.
			// Examples:
			// - Watch pui-select/pui-datatable with events
			// - change values or set new values "this.$set(modalData,'field3', null)"
			// ...
			console.log(modalData);
		},
		onOkReenviarModal(modalData) {
			const body = {
				emPksMulti: modalData.emPksMulti
			};

			const url = this.$store.getters.getModelByName(this.modelName).url.reenviarMensaje;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('modal.edimensaje.reenviar.title');
			return new Promise((resolve) => {
				this.$puiRequests.patchRequest(
					url,
					body,
					(response) => {
						// Valid response, do code and close modal
						console.log(response);
						resolve(true);
						this.$puiNotify.success(this.$puiI18n.t('puiaction.notifySuccess'), title);
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					},
					(e) => {
						// Error response, do code and not close modal
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title);
						resolve(false);
					}
				);
			});
		},
		onShowReenviarModal(modalData) {
			// Put here code to execute before open modal.
			// Examples:
			// - Watch pui-select/pui-datatable with events
			// - change values or set new values "this.$set(modalData,'field3', null)"
			// ...
			console.log(modalData);
		},
		onShowVerMensajeModal(modalData){
			modalData.mensaje=this.$puiI18n.t('modal.edimensaje.verMensaje.loading');
			const url = this.$store.getters.getModelByName(this.modelName).url.getMensaje;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('modal.edimensaje.verMensaje.title');
			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					url,
					modalData.headerPk,
					(response) => {
						modalData.mensaje = response.data;
						this.keymess++;
						resolve(true);
					},
					(e) => {
						// Error response, do code and not close modal
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title);
						resolve(false);
					}
				);
			});
		},
		onShowUpdMensajeModal(modalData){
			modalData.mensaje=this.$puiI18n.t('modal.edimensaje.updMensaje.loading');
			const url = this.$store.getters.getModelByName(this.modelName).url.getMensaje;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('modal.edimensaje.verMensaje.title');
			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					url,
					modalData.headerPk,
					(response) => {
						modalData.mensaje = response.data;
						this.keymess++;
						resolve(true);
					},
					(e) => {
						// Error response, do code and not close modal
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title);
						resolve(false);
					}
				);
			});
		},
		onOkUpdMensajeModal(modalData) {
			const body = {
				idmensaje: modalData.headerPk.idmensaje,
				mensaje: modalData.mensaje
			};
			const url = this.$store.getters.getModelByName(this.modelName).url.updMensaje;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('modal.edimensaje.updMensaje.title');
			return new Promise((resolve) => {
				this.$puiRequests.patchRequest(
					url,
					body,
					(response) => {
						// Valid response, do code and close modal
						console.log(response);
						resolve(true);
						this.$puiNotify.success(this.$puiI18n.t('puiaction.notifySuccess'), title);
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					},
					(e) => {
						// Error response, do code and not close modal
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title);
						resolve(false);
					}
				);
			});
		}
	}
};
</script>
