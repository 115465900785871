import utilActions from '@/utils/actions';
const reprocesarAction = {
	id: 'idReprocesarAction',
	selectionType: 'multiple', // [single|multiple|general]
	label: 'action.edimensaje.reprocesarAction',
	functionality: "WRITE_EDIMENSAJE", // set the functionality if needed
	showInForm: true,
	checkAvailability: function (/*registries*/) {
		// Validation to execute action
		return true;
	},
	runAction: function (action, model, registries) {
		let row = registries[0];
		let emPksMulti = [];
		let pk = {};

		if (registries.length <= 1) {
			row.headerPk = utilActions.getPkFromModel(model, row);
			let pk = {
				idmensaje: row.idmensaje
			};
			emPksMulti.push(pk);
		} else {
			for (let i = 0; i < registries.length; i++) {
				const registry = registries[i];
				pk = {
					idmensaje: registry.idmensaje
				};

				emPksMulti.push(pk);
			}
		}

		row.emPksMulti = emPksMulti;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-edimensajeReprocesarAction-' + model.name + '-show', row);
	}
};
const reenviarAction = {
	id: 'idReenviarAction',
	selectionType: 'multiple', // [single|multiple|general]
	label: 'action.edimensaje.reenviarAction',
	functionality: "WRITE_EDIMENSAJE", // set the functionality if needed
	showInForm: true,
	checkAvailability: function (/*registries*/) {
		// Validation to execute action
		return true;
	},
	runAction: function (action, model, registries) {
		let row = registries[0];
		let emPksMulti = [];
		let pk = {};

		if (registries.length <= 1) {
			row.headerPk = utilActions.getPkFromModel(model, row);
			let pk = {
				idmensaje: row.idmensaje
			};
			emPksMulti.push(pk);
		} else {
			for (let i = 0; i < registries.length; i++) {
				const registry = registries[i];
				pk = {
					idmensaje: registry.idmensaje
				};

				emPksMulti.push(pk);
			}
		}

		row.emPksMulti = emPksMulti;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-edimensajeReenviarAction-' + model.name + '-show', row);
	}
};

const verMensaje = {
	id: 'idVerMensajeAction',
	selectionType: 'single',
	label: 'action.edimensaje.verMensajeAction',
	functionality: 'READ_EDIMENSAJE',
	checkAvailability: function () {
		return true;
	},
	runAction: function (action, model, registries) {
		let row = registries[0];
		row.headerPk = utilActions.getPkFromModel(model, row);
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-edimensajeVerMensajeAction-' + model.name + '-show', row);
	}
};

const updMensaje = {
	id: 'idUpdMensajeAction',
	selectionType: 'single',
	label: 'action.edimensaje.updMensajeAction',
	functionality: 'UPDATE_EDIMENSAJE_MENSAJE',
	checkAvailability: function () {
		return true;
	},
	runAction: function (action, model, registries) {
		let row = registries[0];
		row.headerPk = utilActions.getPkFromModel(model, row);
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-edimensajeUpdMensajeAction-' + model.name + '-show', row);
	}
};

export default {
	gridactions: [reprocesarAction, reenviarAction, verMensaje, updMensaje],
	formactions: []
}
