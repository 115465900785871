<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
			:showCreateBtn="false"
			:showDeleteBtn="false"
			readOnly
		></pui-datatable>
		<edimensaje-modals
			:modelName="modelName"
		></edimensaje-modals>
	</div>
</template>

<script>
import edimensajeActions from './EdimensajeActions';
import edimensajeModals from './EdimensajeModals.vue';

export default {
	name: 'edimensaje-grid',
	components: {
		'edimensaje-modals': edimensajeModals
	},
	data() {
		return {
			modelName: 'edimensaje',
			actions: edimensajeActions.gridactions,
			modelColumnDefs: {
			}
		}
	}
}
</script>
